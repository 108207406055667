<template>
  <a href="https://www.buymeacoffee.com/matusstafura" target="_blank">
    <img
      class="flex w-full h-8"
      src="https://www.buymeacoffee.com/assets/img/custom_images/orange_img.png"
      alt="Buy Me A Coffee"
      target="_blank"
    />
  </a>
</template>
