<template>
  <div class="leading-relaxed">
    <h1 class="pb-4 text-2xl font-bold">Welcome to TailCake, yay!</h1>

    <p>
      Feel free to use any of the components created with Tailwind CSS under MIT
      licence. Reponsive components with dark mode suppport included.
    </p>
    <p>
      If you want to contribute and for more info, please visit,
      <a href="https://github.com/matusstafura/tailcake" class="text-blue-500">
        https://github.com/matusstafura/tailcake
      </a>
    </p>
    <br />
    <p>Checkout the web weekly for more new features and updates 🙈.</p>
  </div>
</template>
