<template>
  <div
    id="sidebar"
    :class="[
      visible
        ? 'w-64 h-screen max-h-screen px-8 hidden fixed lg:block'
        : 'hidden p-4',
    ]"
  >
    <router-link to="alerts" class="block mb-4 hover:underline"
      >Alerts</router-link
    >
    <router-link to="blog" class="block mb-4 hover:underline">Blog</router-link>
    <router-link to="breadcrumbs" class="block mb-4 hover:underline"
      >Breadcrumbs</router-link
    >
    <router-link to="buttons" class="block mb-4 hover:underline"
      >Buttons</router-link
    >
    <router-link to="cards" class="block mb-4 hover:underline"
      >Cards</router-link
    >
    <router-link to="commerce" class="block mb-4 hover:underline"
      >Commerce</router-link
    >
    <router-link to="faq" class="block mb-4 hover:underline">FAQ</router-link>
    <router-link to="cta" class="block mb-4 hover:underline">CTA</router-link>
    <router-link to="footer" class="block mb-4 hover:underline"
      >Footers</router-link
    >
    <router-link to="forms" class="block mb-4 hover:underline"
      >Forms</router-link
    >
    <router-link to="navbars" class="block mb-4 hover:underline"
      >Navbars</router-link
    >
    <router-link to="notifications" class="block mb-4 hover:underline"
      >Notifications</router-link
    >
    <router-link to="pagination" class="block mb-4 hover:underline"
      >Pagination</router-link
    >
    <router-link to="pricing" class="block mb-4 hover:underline"
      >Pricing</router-link
    >
    <router-link to="ratings" class="block mb-4 hover:underline"
      >Ratings</router-link
    >
    <router-link to="sidebar" class="block mb-4 hover:underline"
      >Sidebar</router-link
    >
    <router-link to="steps" class="block mb-4 hover:underline"
      >Steps</router-link
    >
    <router-link to="tables" class="block mb-4 hover:underline"
      >Tables</router-link
    >
  </div>
  <div
    id="sidebar"
    :class="[
      visible
        ? 'hidden p-4'
        : 'bg-white w-64 border h-screen shadow-xl lg:hidden max-h-screen px-8 fixed top-16 z-20 p-4',
    ]"
  >
    <router-link to="alerts" class="block mb-4 hover:underline"
      >Alerts</router-link
    >
    <router-link to="blog" class="block mb-4 hover:underline">Blog</router-link>
    <router-link to="breadcrumbs" class="block mb-4 hover:underline"
      >Breadcrumbs</router-link
    >
    <router-link to="buttons" class="block mb-4 hover:underline"
      >Buttons</router-link
    >
    <router-link to="cards" class="block mb-4 hover:underline"
      >Cards</router-link
    >
    <router-link to="commerce" class="block mb-4 hover:underline"
      >Commerce</router-link
    >
    <router-link to="faq" class="block mb-4 hover:underline">FAQ</router-link>
    <router-link to="cta" class="block mb-4 hover:underline">CTA</router-link>
    <router-link to="footer" class="block mb-4 hover:underline"
      >Footers</router-link
    >
    <router-link to="forms" class="block mb-4 hover:underline"
      >Forms</router-link
    >
    <router-link to="navbars" class="block mb-4 hover:underline"
      >Navbars</router-link
    >
    <router-link to="notifications" class="block mb-4 hover:underline"
      >Notifications</router-link
    >
    <router-link to="pagination" class="block mb-4 hover:underline"
      >Pagination</router-link
    >
    <router-link to="pricing" class="block mb-4 hover:underline"
      >Pricing</router-link
    >
    <router-link to="ratings" class="block mb-4 hover:underline"
      >Ratings</router-link
    >
    <router-link to="sidebar" class="block mb-4 hover:underline"
      >Sidebar</router-link
    >
    <router-link to="steps" class="block mb-4 hover:underline"
      >Steps</router-link
    >
    <router-link to="tables" class="block mb-4 hover:underline"
      >Tables</router-link
    >
  </div>
</template>

<script>
export default {
  props: ["visible"],
};
</script>
